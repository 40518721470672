<template>
	<!-- 续费开通 -->
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.renew')}}</span>
			</div>
			
			<div class="content">
				<!-- 活动折扣提示 -->
				<el-alert class="discount"
					:closable="false"
					type="success">
					<slot>
						<div>{{$t('tip.discountTip1')}}</div>
						<!-- 限时活动 -->
						<!-- <div v-if="discount<1">{{$t('tip.discountTip2')}}</div> -->
					</slot>
				</el-alert>
				
				<div class="renew_box" :class="{renew_box2:discount==1}">
					<div class="head">
						<div class="info_box">
							<!-- 当前套餐 -->
							<div class="rect_box">
								<div class="title">{{$t('renew.vipNow')}}</div>
								<div class="data">{{type_num==1?
									$t('level')[0]:
									type_num==2?
									$t('level')[1]:
									type_num==4?
									$t('level')[2]:
									type_num=='DEX'?
									$t('level')[3]:type_num
								}} 
								( {{
									remain_valid_days>=0?
									$t('renew.remaining',{
										time:remain_valid_days>1?
										remain_valid_days+$t('renew.remainingDays'):
										remain_valid_days+$t('renew.remainingDay')
									}):
									$t('renew.expired')
								}} )
								</div>
							</div>
							<!-- 开通时长 -->
							<div class="rect_box">
								<div class="title">{{$t('renew.opentime')}}</div>
								<div class="data" v-if="pay_time">{{
									pay_time==0||pay_time==1||pay_time==2||pay_time==3?
									$t('renewTime['+pay_time+']'):
									pay_time+$t('renew.remainingDays')
								}}</div>
							</div>
							<!-- 盘口数量 -->
							<div class="rect_box">
								<div class="title">{{$t('renew.openings')}}</div>
								<div class="data">{{pay_pankou_count+$t('renew.individual')}}</div>
							</div>
							<!-- 费用 -->
							<div class="rect_box">
								<div class="title">{{$t('renew.cost')}}</div>
								<div class="data">
									<div>{{can_update==1?pay_num_init:'-'}}</div>
									<div v-if="can_update==1&&pay_num_init>total_balance">{{$t('renew.rechargeNeed')}} {{pay_num_init - total_balance}} USDT</div>
									<div v-else>{{$t('renew.rechargeNeed')}} - USDT</div>
								</div>
							</div>
						</div>
						<!-- 充值 -->
						<div class="recharge_box">
							<div class="title">{{$t('renew.balance')}}</div>
							<div class="balance">{{total_balance}} USDT</div>

							<div class="title">{{$t('renew.rechargeAddress')}}</div>
							<div class="row"
							v-for="(item, index) in rechargeOptions">
								<div :key="index"
								:label="index"
								v-if="item.name!='light_wallet'" class="title">
									{{ item.name.toUpperCase() }}
								</div>
								<div class="text" v-if="item.name!='light_wallet'">
									<input readonly v-model="item.url" type="text" class="inp" :placeholder="$t('renew.rechargeAddress')" />
									<div class="g_btn btn copyBtn" @click="handleCopy(item.url)">{{$t('renew.copyAddress')}}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="cont">
						<div class="cont_box">
							<div class="renew_list">

								<div class="item" v-for="(item,key,index) in vipList" :key="index">
									<div class="caption">{{item.name}}</div>
									<div class="bd">
										<div class="list">
											<div class="title" v-if="key=='2'||key=='4'">{{key=='2'? $t('renew.include')+$t('level[0]'):$t('renew.include')+$t('level[1]')}}</div>
											<ul class="g_list">
												<li v-for="(it,subindex) in item.features" :key="subindex">
													<i class="iconfont icon-checked"></i>{{it.name}}
												</li>
											</ul>
										</div>
										<!-- <div class="form">
											<div class="inp_box">
												<div class="title">{{$t('renew.opentime')}}</div>
												<div class="text">
													<el-select
														class="selectInp" 
														v-model="form[index].month" 
														:placeholder="$t('common.select')">
														<el-option
														:label="$t('renewTime2[0]')"
														:value="0">
														</el-option>
														<el-option
														:label="$t('renewTime2[1]')"
														:value="1">
														</el-option>
														<el-option
														:label="$t('renewTime2[2]')"
														:value="2">
														</el-option>
														<el-option
														:label="$t('renewTime2[3]')"
														:value="3">
														</el-option>
													</el-select>
													<div class="unit"></div>
												</div>
											</div>
											<div class="inp_box">
												<div class="title">{{$t('renew.openings')}}</div>
												<div class="text">
													<input 
														type="text"
														class="inp"
														:placeholder="$t('common.value')"
														v-model="form[index].number"
														ref="numInp"
														@input="handleInput(index)"
														oninput="value=value.replace(/^0{1,}|[^\d]/g,'')"
													>
													<div class="unit">{{$t('renew.individual')}}</div>
												</div>
											</div>
											<div class="price"> -->
												<!-- 折扣价 -->
												<!-- <div class="current"><span>{{discountPrice(item.price,form[index].month,form[index].number)}}</span> USDT</div> -->
												<!-- 原价 -->
												<!-- <s class="origin">
													<template v-if="form[index].month>=1">{{priceFormat(item.price,form[index].month,form[index].number)}} USDT</template>
												</s> -->
											<!-- </div>
											<span class="g_btn btn" @click="handleSwitch(item,index,key)">
												{{$t('renew.switchPackage')}}
											</span>
										</div> -->
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<div class="info_box">
					<div class="title">{{$t('renew.vipNow')}}</div>
					<div class="data_box">
						<div class="package">
							{{type_num==1?
								$t('level')[0]:
								type_num==2?
								$t('level')[1]:
								type_num==4?
								$t('level')[2]:
								type_num=='DEX'?
								$t('level')[3]:type_num
							}} 
							( {{
								remain_valid_days>=0?
								$t('renew.remaining',{
									time:remain_valid_days>1?
									remain_valid_days+$t('renew.remainingDays'):
									remain_valid_days+$t('renew.remainingDay')
								}):
								$t('renew.expired')
							}} )
						</div>
						<div class="sub">{{$t('renew.openings')}}</div>
						<div class="text">
							<span>{{pay_pankou_count+$t('renew.individual')}}</span>
						</div>
						<div class="sub">{{$t('renew.opentime')}}</div>
						<div class="text">
							<span v-if="pay_time">{{
								pay_time==0||pay_time==1||pay_time==2||pay_time==3?
								$t('renewTime['+pay_time+']'):
								pay_time+$t('renew.remainingDays')
							}}</span>
						</div>
						<!-- 费用 -->
						<div class="sub">{{$t('renew.cost')}}</div>
						<div class="text">
							<div>{{can_update==1?pay_num_init:'-'}}</div>
						</div>
						<div class="sub">{{$t('renew.rechargeNeed')}}</div>
						<div class="text">
							<div v-if="can_update==1&&pay_num_init>total_balance">{{pay_num_init - total_balance}} USDT</div>
							<div v-else> - USDT</div>
						</div>
					</div>
				</div>
				<div class="wallet_box">
					<div class="title">{{$t('renew.vipNow')}}</div>
					<div class="data_box">
						<div class="sub">{{$t('renew.balance')}}</div>
						<div class="money">
							{{total_balance}}
						</div>
						<div class="sub">{{$t('renew.rechargeAddress')}}</div>
						<div class="row"
						v-for="(item, index) in rechargeOptions">
							<div :key="index"
							:label="index"
							v-if="item.name!='light_wallet'" class="label">
								{{ item.name.toUpperCase() }}
							</div>
							<div class="text" v-if="item.name!='light_wallet'">
								<input readonly v-model="item.url" type="text" class="inp" :placeholder="$t('renew.rechargeAddress')" />
								<div class="g_btn btn copyBtn" @click="handleCopy(item.url)">{{$t('renew.copyAddress')}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="member_box">
					<!-- 活动折扣提示 -->
					<el-alert class="discount alert_mobile"
						:closable="false"
						type="success">
						<slot>
							<div>{{$t('tip.discountTip1')}}</div>
							<!-- 限时活动 -->
							<!-- <div v-if="discount<1">{{$t('tip.discountTip2')}}</div> -->
						</slot>
					</el-alert>
					<div class="tab_switch">
						<div 
						:class="{active:select_type==key}" 
						v-for="(item,key,index) in vipList" 
						:key="index"
						@click="selectVip(key,index)">
							{{item.name}}
						</div>
					</div>
					<!-- <div class="form">
						<div class="inp_box">
							<div class="title">{{$t('renew.opentime')}}</div>
							<div class="text">
								<el-select
									class="selectInp" 
									v-model="form[select_index].month" 
									:placeholder="$t('common.select')">
									<el-option
									:label="$t('renewTime2[0]')"
									:value="0">
									</el-option>
									<el-option
									:label="$t('renewTime2[1]')"
									:value="1">
									</el-option>
									<el-option
									:label="$t('renewTime2[2]')"
									:value="2">
									</el-option>
									<el-option
									:label="$t('renewTime2[3]')"
									:value="3">
									</el-option>
								</el-select>
								<div class="unit"></div>
							</div>
						</div>
						<div class="inp_box">
							<div class="title">{{$t('renew.openings')}}</div>
							<div class="text">
								<input
									class="inp"
									type="number"
									:placeholder="$t('common.value')"
									v-model="form[select_index].number"
									ref="numInp"
									@input="handleInput(select_index)"
								/>
								<div class="unit">{{$t('renew.individual')}}</div>
							</div>
						</div>
					</div> -->
					<div class="detail">
						<div class="title">{{$t('renew.package')}}</div>
						<div class="detail_box">
							<div class="sub" v-if="select_type=='2'||select_type=='4'">
								{{select_type=='2'? $t('renew.include')+$t('level[0]'):$t('renew.include')+$t('level[1]')}}
							</div>
							<ul class="vip_list" v-if="!!vipList&&!!vipList[select_type]&&vipList[select_type].features.length>0">
								<li class="vip_item" v-for="(item,index) in vipList[select_type].features" :key="index">
									<i class="iconfont icon-checked"></i>
									{{item.name}}
								</li>
							</ul>
						</div>
					</div>
				</div>
				<!-- <div class="buy_btn" style="justify-content: center;" @click="handleSwitch(vipList[select_type],select_index,select_type)"> -->
					<!-- <div class="price"> -->
						<!-- 折扣价 -->
						<!-- <div class="current"><span v-if="!!vipList[select_type]">{{discountPrice(vipList[select_type].price,form[select_index].month,form[select_index].number)}}</span> USDT</div> -->
						<!-- 原价 -->
						<!-- <s class="origin" v-if="!!vipList[select_type]">
							<template v-if="form[select_index].month>=1">{{priceFormat(vipList[select_type].price,form[select_index].month,form[select_index].number)}} USDT</template>
						</s> -->
					<!-- </div> -->
					<!-- <span class="g_btn btn">
						{{$t('renew.switchPackage')}}
					</span>
				</div> -->
			</div>
		</template>

		<!-- 立即续费弹窗 - 套餐续费、切换套餐 -->
		<el-dialog :title="pay_type=='renew'?$t('renew.confirmation1'):$t('renew.confirmation2')" :visible.sync="showOpeninfo" :show-close="false" center>
			<div class="add_form">
				<div class="textinfo">
					<p>{{$t('renew.grade')+'：'+openInfo.name}}</p>
					<p>{{$t('renew.opentime')+'：'}}
						<span v-if="month>=0">{{
							month==0||month==1||month==2||month==3?
							$t('renewTime['+month+']'):
							month+$t('renew.remainingDays')
						}}</span>
						<!-- {{'（'+$t('renew.validity')+'：'+available+'）'}} -->
					</p>
					<p>{{$t('renew.openings')+'：'+number+$t('renew.individual')+$t('renew.openingsTip')}}</p>
					<p>
						{{$t('renew.cost')}}：
						<span :class="{del:discount<1}">
							{{openInfo.pay_num}} USDT
						</span>
						<template v-if="discount<1">（
							<img src="@/assets/images/fire.png" />
							{{$t('renew.activityCost')}}
							{{Math.round(openInfo.pay_num*discount)+' USDT'}}
						）</template>
					</p>
					<!-- 切换套餐 退款、还需支付 -->
					<p v-if="pay_type=='switch'&&!!openInfo.pay_num2">
						<!-- 还需支付 -->
						<template v-if="openInfo.pay_num2>=0">
								{{$t('renew.stillNeed')}}：{{openInfo.pay_num2}} USDT 
						</template>
						<!-- 退款 -->
						<template v-else>
							{{$t('renew.refund')}}：{{-openInfo.pay_num2}} USDT 
						</template>
						<!-- 退款提示 -->
						<span class="tip" v-if="pay_type=='switch'&&!!openInfo.pay_num2&&openInfo.pay_num2<0">
							({{$t('renew.refundTip')}})
						</span>
					</p>
					<!-- 还需充值 -->
					<div class="recharge_need" v-if="(pay_type=='renew'&&openInfo.pay_num>total_balance)||(pay_type=='switch'&&openInfo.pay_num2>0&&openInfo.pay_num2>total_balance)">
						<!-- 是续费，续费金额>余额 -->
						<template v-if="pay_type=='renew'">
							{{$t('renew.rechargeNeed')}}：{{openInfo.pay_num-total_balance}} USDT 
							<!-- 充值提示 -->
							<div class="tip">
								{{$t('renew.rechargePayTip1')}}
							</div>
						</template>
						<!-- 是切换套餐，是还需支付，支付金额>余额 -->
						<template v-else-if="pay_type=='switch'">
							{{$t('renew.rechargeNeed')}}：{{openInfo.pay_num2-total_balance}} USDT 
							<!-- 充值提示 -->
							<div class="tip">
								{{$t('renew.rechargePayTip2')}}
							</div>
						</template>
					</div>
				</div>
				<!-- 套餐续费、切换套餐 -->
				<div class="foot">
					<div class="btn" @click="handleCancel">{{$t('common.cancel')}}</div>
					<!-- 余额不足 -->
					<div class="btn submit" @click="handleSubmit(1)" v-if="(pay_type=='renew'&&openInfo.pay_num>total_balance)||(pay_type=='switch'&&openInfo.pay_num2>0&&openInfo.pay_num2>total_balance)">{{$t('renew.rechargePay')}}</div>
					<!-- 余额充足 -->
					<div class="btn submit" @click="handleSubmit" v-else>{{$t('common.submit')}}</div>
				</div>
			</div>
		</el-dialog>
        <!-- 联系我们弹窗 -->
		<!-- <el-dialog :title="$t('common.contact')" center :visible.sync="showContact" :show-close="false">
			<ul class="contaceList">
                <li v-for="(item,index) in contactList" :key="index" @click="jumpOut(item.wechat)">
                    <i class="iconfont icon-telegram"></i>
                    <span>{{item.nickname}}</span>
                </li>
            </ul>
		</el-dialog> -->
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'renew.styl';
</style>

<script>
	import Clipboard from 'clipboard';
	// var activityCountDown; // 活动倒计时-限时活动
	import { Loading } from 'element-ui';
	import {mapGetters} from "vuex"
	// api
	import {
		getPayInfo,
		pay,
		setPackage,
		getRechargePath,
	} from '@/api/user'
	// import {getService} from '@/api/contact'
	export default ({
		name: 'renew',
		data() {
			return {
				// 活动折扣
				discount:1,//11.1-12.30 95折
				// nowTime:new Date().getTime(),
				// startTime:new Date('2022/11/1').getTime(),
				// endTime:new Date('2022/12/30 23:59:59').getTime(),

				showOpeninfo: false,
				
				// 此处数据仅用于测试,请自行设置数据名称
				total_balance:'0',//余额
				// 续费弹窗数据
				form:[{
					month:'',
					number:'',
				},{
					month:'',
					number:'',
				},{
					month:'',
					number:'',
				},{
					month:'',
					number:'',
				},{
					month:'',
					number:'',
				},{
					month:'',
					number:'',
				}],
				month:'0',
				number:'1',
				available:'',

				is_new_user:1,//当前用户是否是新用户
				can_pay:0,//是否可以支持使用手动续费 0-不支持、1-支持
				can_update:0,//是否可以支持使用修改套餐 0-不支持、1-支持
				type_num:'',//当前等级
				remain_valid_days:'',//剩余套餐时间
				pay_pankou_count:'',//当前盘口数量
				pay_time:'',//当前续费时长
				pay_num:'',//当前套餐续费费用
				pay_num_init:'',//后台设置对应用户的续费价格
				pay_discount:1,//当前套餐的折扣
				openInfo:{},//续费/切换套餐信息
				pay_type:'renew',//支付类型 renew-续费 switch-切换套餐
				count_num:0,//原套餐剩余完整套餐时长的数量 计数

				// 移动端
				select_index:'0',// 选中的等级index
				select_type:'1',// 选中的等级
				
				// 联系我们
				// showContact:false,
				// contactList:[],
				
				// 充值
				rechargeOptions: [
					{
						name: 'TRC20',
						url: '',
						balance: '0',
					},
					{
						name: 'BEP20',
						url: '',
						balance: '0',
					},
				],
			}
		},
		watch: {
			// 是否在活动时间内-限时活动
			// nowTime(newValue, oldValue) {
			// 	if(this.nowTime>=this.startTime&&this.nowTime<this.endTime){
			// 		this.discount=0.95
			// 	}else{
			// 		this.discount=1
			// 	}
			// 	return new Date().getTime()
			// },
		},
		computed:{
			...mapGetters(['account','available_time','isMobile','vipList']),
		},
		created(){
			// 活动倒计时-限时活动
			// if(activityCountDown){
			// 	clearInterval(activityCountDown)
			// 	activityCountDown=''
			// }
			// activityCountDown=setInterval(()=>{
			// 	this.nowTime=new Date().getTime()
			// },1000)

			const loading = Loading.service({
				target: '.main',
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)',
				customClass: 'apploading',
			});
			this.getRechargePath()
			this.$store.dispatch('markets/getVipList').then(()=>{
				this.getPayInfo().then(()=>{
					loading.close()
				}).catch(()=>{
					loading.close()
				})
        	})
			this.getRechargePath()
		},
		methods: {
			// 复制
			handleCopy(code) {
				let clipboard = new Clipboard('.copyBtn', {
					text: () => {
						return code
					}
				})
				clipboard.on('success', () => {
					this.$message({
						message: this.$t('tip.copysuccess'),
						type: 'success',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
				clipboard.on('error', () => {
					this.$message({
						message: this.$t('tip.copyerr'),
						type: 'error',
						center: true,
						customClass: 'shotMsg',
					});
					clipboard.destroy();
				})
			},
			// 充值地址及余额
			async getRechargePath(){
				try{
					let res=await getRechargePath({account:this.account})
					console.log(res)
					var rechargeOptions=[]
					var wallet_list=res.wallet_list
					for(var key in wallet_list){
						var obj={
							name:key,
							url: wallet_list[key].address,
							balance: wallet_list[key].balance,
						}
						rechargeOptions.push(obj)
					}
					this.$set(this,'rechargeOptions',rechargeOptions.reverse())
					this.rechargeOptions=rechargeOptions
				}catch(err){
					if(err.code==2176){
						let message = (err.error && err.error.message) || err.message || err.msg ||this.$t('tip.unknownErr');
						this.$confirm(message, this.$t('tip.tips'), {
							confirmButtonText: this.$t('common.confirm'),
							center: true,
							showClose: false,
							customClass: 'deleteOk',
							showCancelButton:false,
						}).then(()=>{});
					}
				}
			},
			// 续费、切换套餐后 数据更新
			dataInit(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				this.getPayInfo().then(()=>{
					loading.close()
				}).catch(()=>{
					loading.close()
				})
			},
			// get userInfo
			async getPayInfo(){
				let res=await getPayInfo({account:this.account})
				// console.log(res)
				this.type_num=res.type;
				this.pay_num=res.pay_num
				this.pay_num_init=res.pay_num_init
				this.total_balance=res.pay_left
				this.pay_pankou_count=res.pay_pankou_count
				this.pay_time=res.pay_time
				this.remain_valid_days=res.remain_valid_days
				this.is_new_user=res.is_new_user
				this.pay_discount=res.pay_discount
				this.can_pay=res.can_pay
				this.can_update=res.can_update

				// 套餐默认时长、盘口数
				var month=Number(res.pay_time)>3?0:Number(res.pay_time)
				var vipKeys=Object.keys(this.vipList)
				var pay_index=vipKeys.indexOf(res.type)
				this.form.forEach((item,index)=>{
					if(pay_index==index){
						this.$set(this.form[pay_index],'month',month)
						this.$set(this.form[pay_index],'number',res.pay_pankou_count)
					}else{
						this.$set(this.form[index],'month',0)
						this.$set(this.form[index],'number',1)
					}
				})
			},
			// 跳转充值/提现页面 跳转前需钱包错误判定
			jump(path){
				this.$router.push({
					path,
				})
			},
			// 联系客服
			// contact(){
			// 	getService({
			// 		account:this.account,
			// 		source:1,//0-微信，1-telegram，2-Facebook，3-discord
			// 	}).then(res=>{
			// 		if(!!res){
			// 			if(res.length==1){
			// 				// 如果只有一个联系方式 直接打开
			// 				window.location.href=res[0].wechat
			// 			}else if(res.length>1){
			// 				// 如果有多个联系方式 打开弹窗展示列表
			// 				this.contactList=res
			// 				this.showContact=true
			// 			}else{
			// 				// 没有联系方式
			// 			}
			// 		}
			// 	})
			// },
			// 跳外部链接
			jumpOut(path){
				window.location.href=path
			},
			// 续费 - 打开付费弹窗
			handleRenew(){
				this.pay_type='renew'
				// 测试权限及can_pay权限
				if(this.can_pay==1){
					// if(this.account=='qqqqqq1'){
						// 续费弹窗
						this.openInfo={
							name:this.type_num==1?
								this.$t('level')[0]:
								this.type_num==2?
								this.$t('level')[1]:
								this.type_num==4?
								this.$t('level')[2]:
								this.type_num=='DEX'?
								this.$t('level')[3]:this.type_num,
							type_num:this.type_num,
							// pay_num:this.pay_num,
							pay_num:this.can_update==0?this.pay_num_init:this.pay_num,
							
						};
						this.month=this.pay_time||0
						this.number=this.pay_pankou_count||1

						this.showOpeninfo = true;
					// }else{
					// 	// 暂不支持手动续费，给您带来的不便请谅解，请您联系商务客服。
					// 	this.$confirm(this.$t('tip.renewTip2'), this.$t('tip.tips'), {
					// 		confirmButtonText: this.$t('common.confirm'),
					// 		center: true,
					// 		showClose: false,
					// 		customClass: 'deleteOk',
					// 		showCancelButton:false,
					// 	});
					// }
				}else{
					// 您的账号暂不能使用续费功能，请您联系商务客服续费，给您带来的不便请谅解。
					// this.$confirm(this.$t('tip.renewTip3'), this.$t('tip.tips'), {
					// 钱包已锁，请您联系客服处理！
					this.$confirm(this.$t('tip.renewTip6'), this.$t('tip.tips'), {
						confirmButtonText: this.$t('common.confirm'),
						center: true,
						showClose: false,
						customClass: 'deleteOk',
						showCancelButton:false,
					});
				}
			},
			// 盘口数量输入事件 监听
			handleInput(index){
				if(this.isMobile!=1){
					// PC
					var val=this.$refs['numInp'][index].currentValue
					if(val>0){
						this.form[index].number=val
					}
				}else{
					// 移动端
					var val=this.$refs['numInp'].currentValue
					if(val>0){
						this.form[index].number=val
					}
				}
			},
			// 折扣价计算
			discountPrice(price,month,number){
				var monthNum=month==0?1:month==1?3:month==2?6:month==3?12:1
				var original=price*(monthNum||1)*(number||1)
				if(month==3){
					return Math.round(original*0.6)
				}else if(month==2){
					return Math.round(original*0.7)
				}else if(month==1){
					return Math.round(original*0.8)
				}
				return Math.round(original)
			},
			// 原价计算
			priceFormat(price,month,number){
				var monthNum=month==0?1:month==1?3:month==2?6:month==3?12:1
				return price*(monthNum||1)*(number||1)
			},
			// 开通日期推算 开通时间的时间戳 - start_time
			getStartTime(time_num){
				var now_time=new Date().getTime()

				var end_time=Number(time_num)
				// var end_time=new Date(available_time).getTime()
				var end_year=new Date(end_time).getFullYear()
				var end_month=new Date(end_time).getMonth()+1
				var end_day=new Date(end_time).getDate()

				var start_time;
				if(this.pay_time==3){
					// 年付
					var date=(end_year-1)+'/'+end_month+'/'+end_day
					start_time=new Date(date).getTime()
				}else if(this.pay_time==2){
					// 半年付
					var date
					if(end_month>6){
						date=end_year+'/'+(end_month-6)+'/'+end_day
					}else{
						date=(end_year-1)+'/'+(end_month+6)+'/'+end_day
					}
					start_time=new Date(date).getTime()
				}else if(this.pay_time==1){
					// 季付
					var date
					if(end_month>3){
						date=end_year+'/'+(end_month-3)+'/'+end_day
					}else{
						date=(end_year-1)+'/'+(end_month+9)+'/'+end_day
					}
					start_time=new Date(date).getTime()
				}else if(this.pay_time==0){
					// 月付
					var date
					if(end_month>1){
						date=end_year+'/'+(end_month-1)+'/'+end_day
					}else{
						date=(end_year-1)+'/'+(end_month+11)+'/'+end_day
					}
					start_time=new Date(date).getTime()
				}else{
					// n天
					start_time=end_time-86400*this.pay_time
				}

				if(start_time>now_time){
					this.count_num++
					return this.getStartTime(start_time)
				}else{
					return {
						start_time,
						end_time2:time_num
					}
				}
			},
			// 切换套餐 - 打开付费弹窗
			handleSwitch(item,index,key) {
				// console.log(item)
				this.pay_type='switch'
				// 测试权限及can_update权限
				if(this.can_update==1){
					// if(this.account=='qqqqqq1'){
						var month=this.form[index].month||0
						var number=this.form[index].number||1
						this.month=month
						this.number=number
						
						var available_time=this.available_time.replace(/-/g,'/')
						var now_time=new Date().getTime()
						var end_time=new Date(available_time).getTime()

						// 新套餐费用-原价，无活动打折
						var total_money_new=this.discountPrice(item.price,month,number)

						if(now_time>end_time||this.is_new_user==1){
							// 到期
							this.openInfo={
								name:item.name,
								price:item.price,
								type_num:key,
								pay_num:total_money_new,
								pay_num2:total_money_new*this.discount,//还需支付或退款费用
							};
						}else{
							// 未到期

							// 开通日期推算 开通时间的时间戳 - start_time
							this.count_num=0
							var {start_time,end_time2}=this.getStartTime(end_time)
							// console.log(this.count_num)
							
							// 原套餐总天数 - day_num_all
							var day_num_all=(end_time2-start_time)/86400000
							// console.log(day_num_all,start_time)

							// 原套餐单天费用=原套餐费用*原套餐折扣/原套餐总天数
							var day_money=this.pay_num*this.pay_discount/day_num_all
							// console.log(this.pay_num,this.pay_discount,day_money)

							// 原套餐剩余价值=原套餐单天费用*不完整的原套餐剩余天数+原套餐费用*完整的套餐时长剩余次数
							var remain_money=day_money*(Math.floor((end_time2-now_time)/86400000))+this.pay_num*this.pay_discount*this.count_num
							// console.log(remain_money)

							// 续费弹窗
							this.openInfo={
								name:item.name,
								price:item.price,
								type_num:key,
								pay_num:total_money_new,
								pay_num2:(total_money_new*this.discount-remain_money).toFixed(2),
							};
						}

						this.showOpeninfo = true;
					// }else{
					// 	// 暂不支持修改套餐，给您带来的不便请谅解，请您联系商务客服。
					// 	this.$confirm(this.$t('tip.renewTip5'), this.$t('tip.tips'), {
					// 		confirmButtonText: this.$t('common.confirm'),
					// 		center: true,
					// 		showClose: false,
					// 		customClass: 'deleteOk',
					// 		showCancelButton:false,
					// 	});
					// }
				}else{
					// this.$confirm(this.$t('tip.renewTip4'), this.$t('tip.tips'), {
					// 钱包已锁，请您联系客服处理！
					this.$confirm(this.$t('tip.renewTip6'), this.$t('tip.tips'), {
						confirmButtonText: this.$t('common.confirm'),
						center: true,
						showClose: false,
						customClass: 'deleteOk',
						showCancelButton:false,
					});
				}
			},
			handleCancel() {
				this.showOpeninfo = false;
			},
			// 续费弹窗-确定续费
			handleSubmit(is_lock) {
				this.showOpeninfo = false;
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				if(this.pay_type=='renew'){// 套餐续费
					pay({
						account:this.account,
						pay_num:Math.round(this.openInfo.pay_num*this.discount),
						type:this.openInfo.type_num,
						pay_pankou_count:this.number,
						pay_time:this.month,
						is_lock:is_lock==1?is_lock:0,
					}).then(res=>{
						loading.close()
						// console.log(res)
						if(res==1){
							// 续费成功
							this.$confirm(this.$t('tip.renewalSuc'), this.$t('tip.tips'), {
								confirmButtonText: this.$t('common.confirm'),
								center: true,
								showClose: false,
								customClass: 'deleteOk',
								showCancelButton:false,
							});
							// this.$message({
							// 	message: this.$t('tip.renewalSuc'),
							// 	type: 'success',
							// 	center: true,
							// 	customClass: 'shotMsg',
							// });
							this.form=[{},{},{}]
							// 更新账户信息
							this.$store.dispatch('user/getInfo');
							this.dataInit()
						}else{
							if(!is_lock){
								// 余额不足 去充值
								this.$confirm(this.$t('tip.renewalInsufficient'), this.$t('tip.tips'), {
									confirmButtonText: this.$t('common.recharge'),
									cancelButtonText: this.$t('common.back'),
									center: true,
									showClose: false,
									customClass: 'deleteOk',
								}).then(()=>{
									this.jump('/recharge')
								});
							}else{
								// 跳转充值
								this.jump('/recharge')
							}
						}
					}).catch(er=>{
						loading.close()
					})
				}else{// 切换套餐
					setPackage({
						account:this.account,
						pay_num:this.openInfo.pay_num2,
						type:this.openInfo.type_num,
						pay_pankou_count:this.number,
						pay_time:this.month,
						is_lock:is_lock==1?is_lock:0,
					}).then(res=>{
						loading.close()
						// console.log(res)
						if(res==1){
							// 套餐修改成功
							this.$confirm(this.$t('tip.setPackageSuc'), this.$t('tip.tips'), {
								confirmButtonText: this.$t('common.confirm'),
								center: true,
								showClose: false,
								customClass: 'deleteOk',
								showCancelButton:false,
							});
							this.form=[{},{},{}]
							// 更新账户信息
							this.$store.dispatch('user/getInfo');
							this.dataInit()
						}else{
							if(!is_lock){
								// 余额不足 去充值
								this.$confirm(this.$t('tip.setPackageInsufficient'), this.$t('tip.tips'), {
									confirmButtonText: this.$t('common.recharge'),
									cancelButtonText: this.$t('common.back'),
									center: true,
									showClose: false,
									customClass: 'deleteOk',
								}).then(()=>{
									this.jump('/recharge')
								});
							}else{
								this.jump('/recharge')
							}
						}
					}).catch(er=>{
						loading.close()
					})
				}
			},
			
			// 移动端
			// 选择套餐等级-移动端
			selectVip(key,index){
				this.select_type=key;
				this.select_index=index;
			},
		},
		// beforeDestroy() {
		// 	if(activityCountDown){
		// 		clearInterval(activityCountDown)
		// 		activityCountDown=''
		// 	}
		// },
		
	})
</script>
